import { Layout, Card, Collapse, Typography } from "antd";
import MainLayout from "../../layout/MainLayout";
const { Content } = Layout;
const { Panel } = Collapse;
const { Title } = Typography;

const FAQ = () => {
  return (
    <MainLayout theme="light">
      <Content className="faq__content">
        <Title style={{ color: "#49465f" }} level={2}>
          الأسئلة المتكررة
        </Title>
        <Card style={{ width: "93vw" }}>
          <Collapse
            className="faq__collapse"
            accordion
            bordered={false}
            size="large"
            style={{ marginBottom: "25px" }}
          >
            <Panel header="ما القدرات التي يقيسها تطبيق نسبة الإبداع؟" key="1">
              <p>
                {`يقيس تطبيق نسبة الإبداع القدرات الإبداعية الأساسية المرتبطة بالتفكير الإبداعي وفق نموذج جيلفورد "بنية العقل" . وهي الطلاقة والمرونة والأصالة والدرجة الكلية للإبداع. إضافة لذلك. يقيس تطبيق نسبة الإبداع القدرات المشار إليها من خلال اختبارات لفظية وشكلية.`}
              </p>
            </Panel>
            <Panel header="كيف تم تفسير النتائج في تطبيق نسبة الإبداع؟" key="2">
              <p>
                {`يتم تفسير النتيجة التي يحصل عليها الفرد في تطبيق نسبة الإبداع من خلال معيارين هما: (1) الدرجة الخام التي يحصل عليها و (2) العمر الزمني. قد يحصل شخصين على نفس الدرجة الخام ولكن تفسير الدرجة يختلف وفقاً للعمر الزمني وكل ذلك يتم من خلال الخوارزميات التي تم تطويرها للتطبيق.`}
              </p>
            </Panel>
            <Panel
              header={`هل يمكن الإجابة باللهجة العامية (المحلية) أم يجب أن تكون الاستجابات باللغة العربية الفصحى ؟`}
              key="3"
            >
              <p>
                {`نعم، يمكن استخدام اللهجة المحلية للأفراد من دول مجلس التعاون الخليجي حيث تم تطبيق أنشطة التفكير الإبداعي على أكثر من 2,000 شخص من مختلف الأعمار ومن مختلف دول مجلس التعاون وعليه تم بناء قاعدة بيانات ضخمة تضم كافة المرادفات لكل كلمة وفق اللهجات المحلية. على سبيل المثال تعالج الخوارزميات الكلمات التالية بشكل متساو (صيد السمك/ الحداق ) ( كره / تمبه) (جح /يح) (حواجب حيات).`}
              </p>
            </Panel>
            <Panel header="هل يحتاج الاختبار الشكلي أي تدريب في الرسم؟" key="4">
              <p>
                {`على عكس اختبارات الإبداع الشكلية التقليدية والتي تطلب أن يقوم الفرد بإضافة خطوط أو رسم أشكال غير مكتملة، لا يتطلب الاختبار الشكلي الذي تم تطويره في تطبيق نسبة الإبداع أن يقوم الفرد برسم الأشكال. يعتمد الاختبار الشكلي على قدرة الفرد على تطوير منتج أو فكرة من خلال أشكال تعرض عليه وهو متوافق مع نظرية والاك وكوجان.`}
              </p>
            </Panel>
            <Panel header="ما نسبة دقة نتائج تطبيق نسبة الإبداع ؟" key="5">
              <p>
                {`لقد تمت مقارنة نتائج التصحيح من خلال الخوارزميات مع التصحيح اليدوي وكانت نسبة دقة نتائج الخوارزميات أكثر من 95% وهي نسبة أعلى من تلك التي تعتمدها مراكز التدريب على اختبارات الإبداع ومنها مركز تورانس للإبداع وتنمية المواهب %90، على سبيل المثال.`}
              </p>
            </Panel>
            <Panel
              header="هل هنالك بحوث علمية تدعم نتائج تطبيق نسبة الإبداع ؟"
              key="6"
            >
              <p>
                {`حتى الآن، تم نشر عدد 6 بحوث في مجلات عالمية ذات تصنيف متقدم مع نخبة من علماء الإبداع في جامعات أوريجون وشمال تكساس ونبراسكا. أشارت نتائج هذه الأبحاث إلى تمتع الاختبارات بدرجات صدق وثبات عالية وجميعها مطبقة على أفراد من دول مجلس التعاون لدول الخليج العربية. يمكن الحصول على نسخ من هذه الأبحاث من خلال خاصية التواصل في تطبيق نسبة الإبداع.`}
              </p>
            </Panel>
            <Panel header="هل هنالك وقت محدد لأداء الاختبار؟" key="7">
              <p>
                {`يمكنك التقدم للأنشطة الإبداعية في أي وقت من اليوم. تشير الدراسات والبحوث الحديثة أن لكل فرد تفضيلاته فيما يتعلق بتوقيت أداء الاختبارات المعرفية. اختر الوقت الأفضل بالنسبة لك نم جيداً، كل وجبة خفيفة قبل بدء الاختبار، تأكد من أن المكان هادئ ولا توجد ضوضاء واستمتع بتوليد الأفكار والاستجابات غير التقليدية.`}
              </p>
            </Panel>
          </Collapse>
        </Card>
      </Content>
    </MainLayout>
  );
};

export default FAQ;
