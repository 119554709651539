import { message, Row, Col } from "antd";
import config from "../../configs";
import LoginCard from "../../components/LoginCard";

const AdminLogin = ({ setToken }) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div className="admin__auth__wrapper">
      {contextHolder}
      <Row
        gutter={[0, 24]}
        justify="center"
        align="middle"
        className="admin__auth__content"
      >
        <Col xs={{ span: 24 }} xl={{ span: 7 }}>
          <img
            src={config.APP_LOGO_PATH}
            alt="LOGO"
            className="admin__auth__logo"
          />
        </Col>

        <Col
          xs={{ span: 22 }}
          sm={{ span: 12 }}
          xl={{ span: 7 }}
          className="admin__login__card__col"
        >
          <LoginCard
            messageApi={messageApi}
            setToken={setToken}
            isAdmin={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminLogin;
