import axios from "axios";
import { getToken } from "./getToken";

export const handlePreview = (
  test_id,
  user_id,
  setIsPdfPreviewed,
  messageApi
) => {
  setIsPdfPreviewed(true);
  axios
    .get(process.env.REACT_APP_print_api, {
      responseType: "blob",
      params: {
        report_id: test_id,
        user_id: user_id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
      setIsPdfPreviewed(false);
    })
    .catch((err) => {
      setIsPdfPreviewed(false);
      messageApi.error("! لا يمكن عرض هذا التقرير");
      console.log(err.message);
    });
};

export const handleDownload = (
  test_id,
  user_id,
  setIsPdfDownloaded,
  messageApi
) => {
  setIsPdfDownloaded(true);
  axios
    .get(process.env.REACT_APP_print_api, {
      responseType: "blob",
      params: {
        report_id: test_id,
        user_id: user_id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Creativity_user_${user_id}_results.pdf`);
      document.body.appendChild(link);
      link.click();
      setIsPdfDownloaded(false);
    })
    .catch((err) => {
      setIsPdfDownloaded(false);
      messageApi.error("! لا يمكن تحميل هذا التقرير");
      console.log(err.message);
    });
};
