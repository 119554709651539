const BahrainIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 100 15"
    >
      <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="m0 0h100v60H0z" />
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#da291c"
        d="m25 0h75v60H25l15-6-15-6 15-6-15-6 15-6-15-6 15-6-15-6 15-6z"
      />
    </svg>
  );
};

export default BahrainIcon;
