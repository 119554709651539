const FemaleAvatar = () => {
  return (
    <svg
      id="Avatar"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "75px", height: "75px" }}
      viewBox="0 0 56 56"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Ellipse_2"
            data-name="Ellipse 2"
            d="M56,28A28,28,0,1,1,28,0,28,28,0,0,1,56,28Z"
            fill="#c4c4c4"
            opacity="0"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Body"
            d="M0,44.03A14.616,14.616,0,0,1,13.819,29.435V26.087a10.187,10.187,0,0,1-5.756-8.063,2.181,2.181,0,0,1-1.881-2.161V13.37A2.181,2.181,0,0,1,8,11.219V10.182a10.182,10.182,0,1,1,20.363,0v1.037a2.181,2.181,0,0,1,1.818,2.151v2.494A2.181,2.181,0,0,1,28.3,18.024a10.188,10.188,0,0,1-5.755,8.063v3.348A14.616,14.616,0,0,1,36.364,44.03Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Clothes"
            d="M5.819,19.85A14.613,14.613,0,0,1,15.107,6.241c-.01.105-.015.212-.015.319,0,2.938,4.029,5.319,9,5.319s9-2.381,9-5.319c0-.082,0-.163-.01-.243a14.612,14.612,0,0,1,9.1,13.533Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path id="Mask" d="M0,50.526V0H48V50.526Z" fill="none" />
        </clipPath>
        <clipPath id="clip-path-5">
          <path
            id="Combined_Shape"
            data-name="Combined Shape"
            d="M4.072,32.485C1.486,32.219.092,27.034,0,22.559q0-.1,0-.2C.012,16.725,5.173,5.353,7.093,3.071,8.533,1.358,12.492-.077,16.911,0s8.52,1.049,10,2.887C29.152,5.674,33.8,16.259,33.82,22.559q0,.026,0,.052c0,4.458-1.744,8.91-4.182,9.151a28.977,28.977,0,0,1-5.247-.254c-.966-.1-2.005-.2-3.117-.288V28.978a10.183,10.183,0,0,0,5.819-9.2V14.721A23.691,23.691,0,0,1,22.2,11.134a12.349,12.349,0,0,0,2.1,2.921A25.224,25.224,0,0,1,12.587,6.85,22.207,22.207,0,0,1,6.729,14v5.776a10.182,10.182,0,0,0,5.818,9.2v2.378c-1.264.172-2.439.39-3.52.59a25.4,25.4,0,0,1-4.275.571A6.662,6.662,0,0,1,4.072,32.485Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Mask_Group" data-name="Mask Group">
        <path
          id="Ellipse_1"
          data-name="Ellipse 1"
          d="M56,28A28,28,0,1,1,28,0,28,28,0,0,1,56,28Z"
          fill="#bf83ff"
        />
        <path
          id="Ellipse_2-2"
          data-name="Ellipse 2"
          d="M56,28A28,28,0,1,1,28,0,28,28,0,0,1,56,28Z"
          fill="#c4c4c4"
          opacity="0"
        />
        <g id="Mask_Group-2" data-name="Mask Group" clipPath="url(#clip-path)">
          <g
            id="I_hope_your_day"
            data-name="I hope your day"
            transform="translate(4 8)"
          >
            <g id="Body-2" data-name="Body" transform="translate(5.818 6.496)">
              <g id="Body-3" data-name="Body">
                <path
                  id="Body-4"
                  data-name="Body"
                  d="M18.182,0A10.182,10.182,0,0,0,8,10.182v1.036A2.183,2.183,0,0,0,6.182,13.37v2.493a2.182,2.182,0,0,0,1.881,2.161,10.19,10.19,0,0,0,5.755,8.063v3.347A14.617,14.617,0,0,0,0,44.03H36.364a14.617,14.617,0,0,0-13.818-14.6V26.088A10.19,10.19,0,0,0,28.3,18.025a2.182,2.182,0,0,0,1.881-2.161V13.37a2.183,2.183,0,0,0-1.818-2.152V10.182A10.182,10.182,0,0,0,18.182,0Z"
                  fill="#d0c6ac"
                />
                <path
                  id="Body-5"
                  data-name="Body"
                  d="M18.182,0A10.182,10.182,0,0,0,8,10.182v1.036A2.183,2.183,0,0,0,6.182,13.37v2.493a2.182,2.182,0,0,0,1.881,2.161,10.19,10.19,0,0,0,5.755,8.063v3.347A14.617,14.617,0,0,0,0,44.03H36.364a14.617,14.617,0,0,0-13.818-14.6V26.088A10.19,10.19,0,0,0,28.3,18.025a2.182,2.182,0,0,0,1.881-2.161V13.37a2.183,2.183,0,0,0-1.818-2.152V10.182A10.182,10.182,0,0,0,18.182,0Z"
                  fill="none"
                />
              </g>
              <g id="Body-6" data-name="Body" opacity="0">
                <path
                  id="Body-7"
                  data-name="Body"
                  d="M18.182,0A10.182,10.182,0,0,0,8,10.182v1.036A2.183,2.183,0,0,0,6.182,13.37v2.493a2.182,2.182,0,0,0,1.881,2.161,10.19,10.19,0,0,0,5.755,8.063v3.347A14.617,14.617,0,0,0,0,44.03H36.364a14.617,14.617,0,0,0-13.818-14.6V26.088A10.19,10.19,0,0,0,28.3,18.025a2.182,2.182,0,0,0,1.881-2.161V13.37a2.183,2.183,0,0,0-1.818-2.152V10.182A10.182,10.182,0,0,0,18.182,0Z"
                  fill="#d0c6ac"
                />
                <path
                  id="Body-8"
                  data-name="Body"
                  d="M18.182,0A10.182,10.182,0,0,0,8,10.182v1.036A2.183,2.183,0,0,0,6.182,13.37v2.493a2.182,2.182,0,0,0,1.881,2.161,10.19,10.19,0,0,0,5.755,8.063v3.347A14.617,14.617,0,0,0,0,44.03H36.364a14.617,14.617,0,0,0-13.818-14.6V26.088A10.19,10.19,0,0,0,28.3,18.025a2.182,2.182,0,0,0,1.881-2.161V13.37a2.183,2.183,0,0,0-1.818-2.152V10.182A10.182,10.182,0,0,0,18.182,0Z"
                  fill="none"
                />
              </g>
              <g id="Body-9" data-name="Body" clipPath="url(#clip-path-2)">
                <g id="_Skin" data-name="🎨 Skin" transform="translate(-5.818)">
                  <rect
                    id="_Skin_style_"
                    data-name="🎨 Skin (style)"
                    width="48"
                    height="44.03"
                    fill="#ffccd7"
                  />
                  <g id="Color">
                    <rect
                      id="Color-2"
                      data-name="Color"
                      width="48"
                      height="44.03"
                      fill="#ffccd7"
                    />
                    <rect
                      id="Color-3"
                      data-name="Color"
                      width="48"
                      height="44.03"
                      fill="none"
                    />
                  </g>
                </g>
                <g id="Neck_Shadow" data-name="Neck Shadow">
                  <path
                    id="Neck_Shadow-2"
                    data-name="Neck Shadow"
                    d="M0,0V1.444a10.182,10.182,0,0,0,20.364,0V0A10.182,10.182,0,0,1,10.182,10.182,10.182,10.182,0,0,1,0,0Z"
                    transform="translate(8 16.886)"
                    fill="rgba(0,0,0,0.1)"
                  />
                  <path
                    id="Neck_Shadow-3"
                    data-name="Neck Shadow"
                    d="M0,0V1.444a10.182,10.182,0,0,0,20.364,0V0A10.182,10.182,0,0,1,10.182,10.182,10.182,10.182,0,0,1,0,0Z"
                    transform="translate(8 16.886)"
                    fill="none"
                  />
                  <path
                    id="Neck_Shadow-4"
                    data-name="Neck Shadow"
                    d="M0,0V1.444a10.182,10.182,0,0,0,20.364,0V0A10.182,10.182,0,0,1,10.182,10.182,10.182,10.182,0,0,1,0,0Z"
                    transform="translate(8 16.886)"
                    fill="none"
                  />
                </g>
              </g>
            </g>
            <g
              id="_Clothes"
              data-name="👔 Clothes"
              transform="translate(0 30.677)"
            >
              <g id="Clothes-2" data-name="Clothes">
                <path
                  id="Clothes-3"
                  data-name="Clothes"
                  d="M18.273,5.638c4.971,0,9-2.382,9-5.32q0-.122-.009-.243a14.612,14.612,0,0,1,9.1,13.533H0A14.612,14.612,0,0,1,9.289,0q-.016.158-.016.318C9.273,3.256,13.3,5.638,18.273,5.638Z"
                  transform="translate(5.818 6.241)"
                  fill="#6223a9"
                />
                <path
                  id="Clothes-4"
                  data-name="Clothes"
                  d="M18.273,5.638c4.971,0,9-2.382,9-5.32q0-.122-.009-.243a14.612,14.612,0,0,1,9.1,13.533H0A14.612,14.612,0,0,1,9.289,0q-.016.158-.016.318C9.273,3.256,13.3,5.638,18.273,5.638Z"
                  transform="translate(5.818 6.241)"
                  fill="none"
                />
              </g>
              <g id="Clothes-5" data-name="Clothes" opacity="0">
                <path
                  id="Clothes-6"
                  data-name="Clothes"
                  d="M18.273,5.638c4.971,0,9-2.382,9-5.32q0-.122-.009-.243a14.612,14.612,0,0,1,9.1,13.533H0A14.612,14.612,0,0,1,9.289,0q-.016.158-.016.318C9.273,3.256,13.3,5.638,18.273,5.638Z"
                  transform="translate(5.818 6.241)"
                  fill="#e6e6e6"
                />
                <path
                  id="Clothes-7"
                  data-name="Clothes"
                  d="M18.273,5.638c4.971,0,9-2.382,9-5.32q0-.122-.009-.243a14.612,14.612,0,0,1,9.1,13.533H0A14.612,14.612,0,0,1,9.289,0q-.016.158-.016.318C9.273,3.256,13.3,5.638,18.273,5.638Z"
                  transform="translate(5.818 6.241)"
                  fill="none"
                />
              </g>
              <g
                id="_Clothes-2"
                data-name="👔 Clothes"
                clipPath="url(#clip-path-3)"
              >
                <g id="_Color_Fabric" data-name="↳ Color Fabric">
                  <g id="_Color" data-name="🖍Color">
                    <rect
                      id="_Color-2"
                      data-name="🖍Color"
                      width="48"
                      height="19.85"
                      fill="#6223a9"
                    />
                    <rect
                      id="_Color-3"
                      data-name="🖍Color"
                      width="48"
                      height="19.85"
                      fill="none"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="Face" transform="translate(13.818 14.797)">
              <g
                id="_Mouth"
                data-name="👄 Mouth"
                transform="translate(0.364 9.383)"
              >
                <path
                  id="Mouth"
                  d="M0,0A2.536,2.536,0,0,0,2.545,2.526h0A2.536,2.536,0,0,0,5.091,0"
                  transform="translate(7.273 2.707)"
                  fill="#0c092a"
                />
              </g>
              <g
                id="_Nose"
                data-name="👃🏽 Nose"
                transform="translate(5.091 7.218)"
                opacity="0.3"
              >
                <path
                  id="Nose"
                  d="M0,0C0,.8.977,1.444,2.182,1.444h0C3.387,1.444,4.364.8,4.364,0"
                  transform="translate(2.909 1.444)"
                  fill="#0c092a"
                />
              </g>
              <g id="_Eyes" data-name="👁 Eyes" transform="translate(0 1.444)">
                <path
                  id="Eye"
                  d="M2.182,1.083A1.091,1.091,0,1,1,1.091,0,1.087,1.087,0,0,1,2.182,1.083Z"
                  transform="translate(4.364 2.887)"
                  fill="#0c092a"
                />
                <path
                  id="Eye-2"
                  data-name="Eye"
                  d="M2.182,1.083A1.091,1.091,0,1,1,1.091,0,1.087,1.087,0,0,1,2.182,1.083Z"
                  transform="translate(13.818 2.887)"
                  fill="#0c092a"
                />
              </g>
              <g id="_Eyebrow" data-name="✏️ Eyebrow">
                <g
                  id="I_Browse"
                  data-name="I Browse"
                  transform="translate(2.182 1.097)"
                >
                  <path
                    id="Eyebrow"
                    d="M.66,2C1.372,1.005,3.323.448,5,.858a.361.361,0,1,0,.173-.7C3.226-.319.966.326.067,1.581a.359.359,0,0,0,.086.5A.365.365,0,0,0,.66,2Z"
                    fill="#0c092a"
                  />
                  <path
                    id="Eyebrow-2"
                    data-name="Eyebrow"
                    d="M.66-2C1.372-1.005,3.323-.448,5-.858a.364.364,0,0,1,.44.264.361.361,0,0,1-.266.437C3.226.319.966-.326.067-1.581a.359.359,0,0,1,.086-.5A.365.365,0,0,1,.66-2Z"
                    transform="translate(15.999) rotate(180)"
                    fill="#0c092a"
                  />
                </g>
              </g>
            </g>
            <g id="Top">
              <g id="Mask-2" data-name="Mask">
                <rect
                  id="Mask-3"
                  data-name="Mask"
                  width="48"
                  height="50.526"
                  fill="none"
                />
                <rect
                  id="Mask-4"
                  data-name="Mask"
                  width="48"
                  height="50.526"
                  fill="none"
                />
              </g>
              <g id="Mask-5" data-name="Mask" opacity="0">
                <rect id="Mask-6" data-name="Mask" width="48" height="50.526" />
                <rect
                  id="Mask-7"
                  data-name="Mask"
                  width="48"
                  height="50.526"
                  fill="none"
                />
              </g>
              <g id="Top-2" data-name="Top" clipPath="url(#clip-path-4)">
                <g id="Top-3" data-name="Top" transform="translate(-0.182)">
                  <g id="Hair" transform="translate(7.271 3.606)">
                    <g id="Combined_Shape-2" data-name="Combined Shape">
                      <path
                        id="Combined_Shape-3"
                        data-name="Combined Shape"
                        d="M0,22.56C-.11,17,5.151,5.38,7.093,3.071,8.533,1.358,12.492-.078,16.911,0s8.52,1.049,10,2.887C29.152,5.674,33.8,16.258,33.82,22.56c.017,4.475-1.734,8.961-4.182,9.2a29.029,29.029,0,0,1-5.247-.254h0c-.965-.1-2-.2-3.116-.288V28.978a10.183,10.183,0,0,0,5.818-9.2V14.722A23.68,23.68,0,0,1,22.2,11.134,12.373,12.373,0,0,0,24.3,14.056,25.237,25.237,0,0,1,12.587,6.85,22.207,22.207,0,0,1,6.729,14v5.776a10.183,10.183,0,0,0,5.818,9.2v2.378c-1.264.172-2.439.39-3.52.59a19.2,19.2,0,0,1-4.955.538C1.486,32.219.092,27.034,0,22.56Z"
                        fill="#e6e6e6"
                      />
                      <path
                        id="Combined_Shape-4"
                        data-name="Combined Shape"
                        d="M0,22.56C-.11,17,5.151,5.38,7.093,3.071,8.533,1.358,12.492-.078,16.911,0s8.52,1.049,10,2.887C29.152,5.674,33.8,16.258,33.82,22.56c.017,4.475-1.734,8.961-4.182,9.2a29.029,29.029,0,0,1-5.247-.254h0c-.965-.1-2-.2-3.116-.288V28.978a10.183,10.183,0,0,0,5.818-9.2V14.722A23.68,23.68,0,0,1,22.2,11.134,12.373,12.373,0,0,0,24.3,14.056,25.237,25.237,0,0,1,12.587,6.85,22.207,22.207,0,0,1,6.729,14v5.776a10.183,10.183,0,0,0,5.818,9.2v2.378c-1.264.172-2.439.39-3.52.59a19.2,19.2,0,0,1-4.955.538C1.486,32.219.092,27.034,0,22.56Z"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Combined_Shape-5"
                      data-name="Combined Shape"
                      opacity="0"
                    >
                      <path
                        id="Combined_Shape-6"
                        data-name="Combined Shape"
                        d="M0,22.56C-.11,17,5.151,5.38,7.093,3.071,8.533,1.358,12.492-.078,16.911,0s8.52,1.049,10,2.887C29.152,5.674,33.8,16.258,33.82,22.56c.017,4.475-1.734,8.961-4.182,9.2a29.029,29.029,0,0,1-5.247-.254h0c-.965-.1-2-.2-3.116-.288V28.978a10.183,10.183,0,0,0,5.818-9.2V14.722A23.68,23.68,0,0,1,22.2,11.134,12.373,12.373,0,0,0,24.3,14.056,25.237,25.237,0,0,1,12.587,6.85,22.207,22.207,0,0,1,6.729,14v5.776a10.183,10.183,0,0,0,5.818,9.2v2.378c-1.264.172-2.439.39-3.52.59a19.2,19.2,0,0,1-4.955.538C1.486,32.219.092,27.034,0,22.56Z"
                        fill="#e6e6e6"
                      />
                      <path
                        id="Combined_Shape-7"
                        data-name="Combined Shape"
                        d="M0,22.56C-.11,17,5.151,5.38,7.093,3.071,8.533,1.358,12.492-.078,16.911,0s8.52,1.049,10,2.887C29.152,5.674,33.8,16.258,33.82,22.56c.017,4.475-1.734,8.961-4.182,9.2a29.029,29.029,0,0,1-5.247-.254h0c-.965-.1-2-.2-3.116-.288V28.978a10.183,10.183,0,0,0,5.818-9.2V14.722A23.68,23.68,0,0,1,22.2,11.134,12.373,12.373,0,0,0,24.3,14.056,25.237,25.237,0,0,1,12.587,6.85,22.207,22.207,0,0,1,6.729,14v5.776a10.183,10.183,0,0,0,5.818,9.2v2.378c-1.264.172-2.439.39-3.52.59a19.2,19.2,0,0,1-4.955.538C1.486,32.219.092,27.034,0,22.56Z"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Hair-2"
                      data-name="Hair"
                      clipPath="url(#clip-path-5)"
                    >
                      <g
                        id="_Hair_Color"
                        data-name="↳ 💈Hair Color"
                        transform="translate(-7.271 -3.606)"
                      >
                        <g id="Color-4" data-name="Color">
                          <rect
                            id="Color-5"
                            data-name="Color"
                            width="48.364"
                            height="50.526"
                            fill="#0c092a"
                          />
                          <rect
                            id="Color-6"
                            data-name="Color"
                            width="48.364"
                            height="50.526"
                            fill="none"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FemaleAvatar;
