const UaeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 12 2"
    >
      <rect width="12" height="6" fill="#00732f" id="green" />
      <rect width="12" height="4" y="2" fill="#fff" />
      <rect width="12" height="2" y="4" />
      <rect width="3" height="6" fill="#f00" id="red" />
    </svg>
  );
};

export default UaeIcon;
