import { Layout, Col, Row, Menu, Dropdown } from "antd";
import { useState, useContext } from "react";
import Icon, {
  QuestionOutlined,
  HomeOutlined,
  FileTextOutlined,
  MenuOutlined,
  UserOutlined,
  HeartOutlined,
  BookOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import FemaleAvatar from "../../utils/Images/FemaleAvatar";
import MaleAvatar from "../../utils/Images/MaleAvatar";
import { UsernameContext, GenderContext } from "../App/App";
const { Header } = Layout;

const HeaderLayout = () => {
  const isAdmin = sessionStorage.getItem("isAdmin") === "true" ? true : false;
  const currentMenuItem = window.location.pathname.substring(1)
    ? window.location.pathname.substring(1)
    : "home";
  const [current, setCurrent] = useState(currentMenuItem);
  const userName = useContext(UsernameContext);
  const userGender = useContext(GenderContext);
  const [open, setOpen] = useState(false);
  const items = isAdmin
    ? []
    : [
        {
          label: <Link to="/">الرئيسية</Link>,
          key: "home",
          icon: <HomeOutlined />,
        },
        {
          label: <Link to="/tests">التقارير</Link>,
          key: "tests",
          icon: <FileTextOutlined />,
        },
        {
          label: <Link to="/faq">الأسئلة المتكررة</Link>,
          key: "faq",
          icon: <QuestionOutlined />,
        },
        {
          label: <Link to="/terms-of-use">شروط الإستخدام</Link>,
          key: "terms-of-use",
          icon: <BookOutlined />,
        },
        {
          label: <Link to="/about-us">عن نسبة الإبداع</Link>,
          key: "about-us",
          icon: <HeartOutlined />,
        },
      ];
  const dropdown_menu_items = [
    ...items,
    !isAdmin && {
      type: "divider",
    },
    {
      label: (
        <Link to="/profile">{userName.slice(0, userName.indexOf(" "))}</Link>
      ),
      key: "user",
      icon: <UserOutlined />,
    },
  ];

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  return (
    <Header className="layout__header">
      <Row>
        <Col span={5}>
          <Link className="header__logo" to={isAdmin ? null : "/"}>
            <img src="/images/colored-logo.png" alt="logo" />
          </Link>
        </Col>
        <Col span={14}>
          {!isAdmin && (
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              overflowedIndicator={<CaretDownOutlined />}
              mode="horizontal"
              items={items}
              className="header__menu"
            />
          )}
        </Col>
        <Col className="header__user" span={5}>
          {userGender === "ذكر" || userGender === "male" ? (
            <Icon component={MaleAvatar} />
          ) : (
            <Icon component={FemaleAvatar} />
          )}
          <Link className="header__username" to="/profile">
            {userName.slice(0, userName.indexOf(" "))}
          </Link>
        </Col>
        <Col className="header__dropdown">
          <Dropdown
            menu={{
              items: dropdown_menu_items,
              selectedKeys: [current],
            }}
            trigger={["click"]}
            onOpenChange={handleOpenChange}
            open={open}
          >
            <MenuOutlined style={{ color: "black", fontSize: "24px" }} />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderLayout;
