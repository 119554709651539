import { Layout, Card, Typography } from "antd";
import MainLayout from "../../layout/MainLayout";
const { Content } = Layout;
const { Title, Paragraph } = Typography;

const AboutUs = () => {
  return (
    <MainLayout theme="light">
      <Content className="about__content">
        <Title style={{ color: "#49465f" }} level={2}>
          عنَّا
        </Title>
        <Card style={{ width: "93vw" }}>
          <Title level={3} style={{ marginBottom: "30px" }}>
            يعد تطبيق نسبة الإبداع أول أداة عربية تتسم بالصدق والثبات ولها
            معايير مشتقة من البيئة الخليجية وذلك لقياس قدرات التفكير الإبداعي
            (اللفظية والشكلية). يتميز التطبيق بعدد من الابتكارات هي:
          </Title>
          <Paragraph>
            <ul>
              <li>
                يعتمد التطبيق على استخدام الخوارزميات بأسلوب التعلم العميق "Deep
                Learning" كأحد تقنيات الذكاء الاصطناعي.
              </li>
              <li>
                يُعتبر التطبيق الأول من نوعه في مجال معالجة اللغات الطبيعية
                Natural Language Processing (NLP) باللغة العربية.
              </li>
              <li>
                قاعدة البيانات تشتمل على العديد من المرادفات العربية وتبنى عن
                طريق تقنية "التعلم المتزايد Incremental Learning".
              </li>
              <li>
                يحصل المفحوص أو صاحب القرار على تقرير فوري بمجرد انتهاء الطالب
                من أداء الاختبارات، ما يعني انتفاء / إلغاء مفهوم زمن التصحيح.
              </li>
              <li>
                يتعرف على جميع المرادفات الخاصة بجميع اللهجات الخليجية. يعالج
                الخطأ البشري الناتج عن تصحيح اختبارات الإبداع بمعدل دقة يفوق
                95%.
              </li>
              <li>
                تم بناء اختبارات الإبداع على نظريتي تورانس وجيلفورد للتفكير
                التباعدي بصورتين لفظية وشكلية.
              </li>
            </ul>
          </Paragraph>
        </Card>
      </Content>
    </MainLayout>
  );
};

export default AboutUs;
