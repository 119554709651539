import React, { useContext, useEffect, useState, useRef } from "react";
import { GoSell } from "@tap-payments/gosell";
import { Button } from "antd";
import { UserEmailContext, UsernameContext } from "../../layout/App/App";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";
import axios from "axios";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";

const Payment = () => {
  const userEmail = useContext(UserEmailContext);
  const userName = useContext(UsernameContext);

  const hasRun = useRef(false);

  const [testPrice, setTestPrice] = useState(0);

  const uuid = uuidv4();

  const [paymentCookies, setPaymentCookie] = useCookies(["p_completed"]);

  const decryptedToken =
    paymentCookies.p_completed &&
    CryptoJS.AES.decrypt(
      paymentCookies.p_completed,
      process.env.REACT_APP_randomly_secure_payment_key
    ).toString(CryptoJS.enc.Utf8);
  const isPaid =
    decryptedToken === `V1_Payment_For_${userEmail}_${userName}_V1`;

  useEffect(() => {
    userEmail &&
      userName &&
      axios
        .get(
          `${
            process.env.REACT_APP_pre_payment_data
          }?email=${userEmail}&first_name=${userName.slice(
            0,
            userName.indexOf(" ")
          )}&last_name=${userName.slice(userName.indexOf(" "))}`
        )
        .then((response) => {
          if (response?.status === 200) {
            setTestPrice(response?.data?.data?.paymentsitems?.amountPerUnit);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
  }, [userEmail, userName]);

  const savePaymentInfo = (
    first_name,
    last_name,
    email,
    amount,
    created_at,
    payment_method,
    status,
    charge_id,
    customer_id
  ) => {
    axios
      .post(
        process.env.REACT_APP_save_payment,
        {
          first_name: first_name,
          last_name: last_name,
          user_email: email,
          amount: amount,
          payment_date: dayjs(Number(created_at)).format("YYYY-MM-DD HH:mm:ss"),
          payment_method: payment_method,
          payment_status: status === "CAPTURED" ? "success" : "failure",
          charge_id: charge_id,
          customer_id: customer_id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        console.log("Payment Done");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePaymentCallback = (data) => {
    if (
      data &&
      data.callback &&
      data.callback.response &&
      data.callback.response.code === "000"
    ) {
      if (
        data?.callback?.customer &&
        data?.callback?.transaction &&
        data?.callback?.source &&
        !hasRun.current
      ) {
        const encryptedToken = CryptoJS.AES.encrypt(
          `V1_Payment_For_${data.callback.customer.email}_${data.callback.customer.first_name} ${data.callback.customer.last_name}_V1`,
          process.env.REACT_APP_randomly_secure_payment_key
        ).toString();
        setPaymentCookie("p_completed", encryptedToken, {
          path: "/",
        });

        hasRun.current = true;

        savePaymentInfo(
          data.callback.customer.first_name,
          data.callback.customer.last_name,
          data.callback.customer.email,
          data.callback.amount,
          data.callback.transaction.created,
          data.callback.source.payment_method,
          data.callback.status,
          data.callback.id,
          data.callback.customer.id
        );
      }
      // window.location.replace(`${window.location.origin}/stages/1`);
    }
  };

  return (
    <>
      <Button
        size="large"
        shape="round"
        style={{ margin: 12, fontSize: 24, height: "fit-content" }}
        // onClick={() => GoSell.openLightBox()}
        onClick={() => GoSell.openPaymentPage()}
        className={isPaid || !testPrice ? "disabled-payment-btn" : ""}
        disabled={isPaid || !testPrice}
        loading={!testPrice}
      >
        الدفع
      </Button>

      <GoSell
        gateway={{
          publicKey: process.env.REACT_APP_public_tab,
          language: "en",
          contactInfo: true,
          supportedCurrencies: "all",
          // supportedPaymentMethods: [
          //   "VISA",
          //   "MASTERCARD",
          //   // "AMERICAN_EXPRESS",
          //   // "AMEX",
          //   "BENEFIT",
          // ],
          supportedPaymentMethods: "all",
          saveCardOption: true,
          customerCards: true,
          notifications: "standard",
          callback: (data) => handlePaymentCallback(data),
          labels: {
            cardNumber: "رقم البطاقة",
            expirationDate: "شهر/سنة",
            cvv: "الرقم السرى",
            cardHolder: "الإسم على البطاقة",
            actionButton: "ادفع",
          },
          style: {
            base: {
              color: "#535353",
              lineHeight: "18px",
              fontFamily: "sans-serif",
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "rgba(0, 0, 0, 0.26)",
                fontSize: "15px",
              },
            },
            invalid: {
              color: "red",
              iconColor: "#fa755a ",
            },
          },
        }}
        customer={{
          first_name: userName.slice(0, userName.indexOf(" ")),
          middle_name: "",
          last_name: userName.slice(userName.indexOf(" ") + 1),
          email: userEmail,
          // phone: {
          //   country_code: "965",
          //   number: "99999999",
          // },
        }}
        order={{
          // amount: 10.0,
          amount: testPrice,
          currency: "BHD",
          items: [
            {
              id: 1,
              name: "نشاط نسبة الإبداع",
              description: "نشاط لقياس نسبة الإبداع للطالب",
              quantity: "1",
              // amount_per_unit: "BD10.000",
              amount_per_unit: `BD${testPrice}`,
              // discount: {
              //   type: "P",
              //   value: "10%",
              // },
              // total_amount: "BD10.000",
              total_amount: `BD${testPrice}`,
            },
          ],
          shipping: null,
          taxes: null,
        }}
        transaction={{
          mode: "charge",
          charge: {
            saveCard: true,
            threeDSecure: true,
            description: "النشاط الخاص بنسبة الإبداع",
            statement_descriptor: "Sample",
            reference: {
              transaction: uuid,
              order: uuid,
            },
            metadata: {},
            receipt: {
              email: true,
              // sms: true,
            },
            // redirect: "",
            post: null,
          },
        }}
      />
    </>
  );
};

export default Payment;
