import { Layout, Typography, Row, Col } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Footer } = Layout;

const FooterSection = () => {
  const isAdmin = sessionStorage.getItem("isAdmin") === "true" ? true : false;
  const version = "1.0.0.8";

  return (
    <Footer className="footer__wrapper">
      {!isAdmin && (
        <>
          <div className="footer_contact">
            <div>
              <Link to="tel:+97338030888" className="contact__info">
                <Typography.Title level={4}>
                  <PhoneOutlined style={{ marginLeft: "10px" }} />
                  رقم الهاتف
                </Typography.Title>
                <Typography.Title level={5}>97338030888+</Typography.Title>
              </Link>
            </div>
            <div>
              <Link
                to="mailto:sales@creative-sparkle.com"
                className="contact__info"
              >
                <Typography.Title level={4}>
                  <MailOutlined style={{ marginLeft: "10px" }} />
                  البريد الإلكتروني
                </Typography.Title>
                <Typography.Title level={5}>
                  sales@creative-sparkle.com
                </Typography.Title>
              </Link>
            </div>
          </div>
          <Row className="footer_info">
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img
                  className="footer_logo"
                  src="/images/colored-logo.png"
                  alt="logo"
                />
              </Link>
              <div className="footer_store">
                <Link
                  to="https://apps.apple.com/us/app/%D9%86%D8%B3%D8%A8%D8%A9-%D8%A7%D9%84%D8%A5%D8%A8%D8%AF%D8%A7%D8%B9/id6447567793"
                  target="_blank"
                >
                  <img
                    id="app_store"
                    src="/images/app_store.png"
                    alt="app_store"
                  />
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=app.acute.creativity_quotient&pcampaignid=web_share"
                  target="_blank"
                >
                  <img src="/images/play_store.png" alt="play_store" />
                </Link>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }} className="footer_links">
              <Typography.Title level={3} style={{ color: "#6c5ce4" }}>
                نسبة الإبداع
              </Typography.Title>
              <Row gutter={[20, 20]} style={{ marginRight: "7px" }}>
                <Col span={12}>
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    الرئيسية
                  </Link>
                </Col>
                <Col span={12}>
                  <Link to="/tests" onClick={() => window.scrollTo(0, 0)}>
                    التقارير
                  </Link>
                </Col>
                <Col span={12}>
                  <Link to="/profile" onClick={() => window.scrollTo(0, 0)}>
                    حسابي
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 8 }}
              style={{
                padding: "10px 0",
                textAlign: "center",
              }}
            >
              <Row gutter={[16, 16]} justify="center" align="middle">
                <Col span={12}>
                  <img width={88} src="/images/tab_logo.png" alt="tab_logo" />
                </Col>
                <Col span={12}>
                  <img
                    width={100}
                    src="/images/benefit_logo.png"
                    alt="benefit_logo"
                  />
                </Col>
                <Col span={8}>
                  <img width={50} src="/images/visa_logo.png" alt="visa_logo" />
                </Col>
                <Col span={8}>
                  <img
                    width={50}
                    src="/images/master_card_logo.png"
                    alt="master_card_logo"
                  />
                </Col>
                <Col span={8}>
                  <img width={40} src="/images/amex_logo.png" alt="amex_logo" />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <div className={isAdmin ? "admin_footer_rights" : "footer_rights"}>
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          <Typography.Text strong>
            © {new Date().getUTCFullYear()} نسبة الإبداع {version}
          </Typography.Text>
          جميع الحقوق محفوظة.
        </Typography.Paragraph>
        {!isAdmin && (
          <div>
            <Link to="/faq" onClick={() => window.scrollTo(0, 0)}>
              الأسئلة المتكررة
            </Link>
            <Link to="/terms-of-use" onClick={() => window.scrollTo(0, 0)}>
              شروط الإستخدام
            </Link>
            <Link to="/about-us" onClick={() => window.scrollTo(0, 0)}>
              عن نسبة الإبداع
            </Link>
          </div>
        )}
      </div>
    </Footer>
  );
};

export default FooterSection;
