import { useContext } from "react";
import { Layout, Col, Row, Card, Typography, Tooltip } from "antd";
import { StarFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import MainLayout from "../../layout/MainLayout";
import HaveCoupon from "./HaveCoupon";
import Payment from "./Payment";
import { UserEmailContext, UsernameContext } from "../../layout/App/App";
import ToStagesButton from "./ToStagesButton";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";
const { Content } = Layout;

const Home = () => {
  const userEmail = useContext(UserEmailContext);
  const userName = useContext(UsernameContext);

  const commonContentStyles = { color: "white", fontWeight: 500 };

  const homeContent = [
    `يعتمد التطبيق على استخدام الخوارزميات بأسلوب "التعلم العميق Deep
Learning" كأحد تقنيات الذكاء الاصطناعي`,
    `يعتبر التطبيق الأول من نوعه في مجال معالجة اللغات الطبيعية Natural
Language Processing (NLP) باللغة العربية`,
  ];

  const [paymentCookies] = useCookies(["p_completed"]);

  const decryptedToken =
    paymentCookies.p_completed &&
    CryptoJS.AES.decrypt(
      paymentCookies.p_completed,
      process.env.REACT_APP_randomly_secure_payment_key
    ).toString(CryptoJS.enc.Utf8);
  const isPaid =
    decryptedToken === `V1_Payment_For_${userEmail}_${userName}_V1`;

  return (
    <MainLayout theme="dark">
      <Content className="home__content">
        <Row>
          <Col span={24} style={{ marginBottom: 48, marginTop: 32 }}>
            <Typography.Title
              level={1}
              className="text-center responsive-h1"
              style={{
                ...commonContentStyles,
              }}
            >
              بين يديك الآن أول أداة عربية تتسم بالصدق والثبات ولها معايير مشتقة
              من البيئة الخليجية وذلك لقياس قدرات التفكير الإبداعي (اللفظية
              والشكلية)
            </Typography.Title>
          </Col>

          {homeContent.map((val) => (
            <div key={val}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBlock: 6,
                }}
              >
                <StarFilled
                  style={{ color: "yellow", fontSize: 32, marginTop: "0.4em" }}
                />
                <Typography.Title
                  level={2}
                  className="responsive-h2"
                  style={{ ...commonContentStyles, marginRight: 12 }}
                >
                  {val.trim()}
                </Typography.Title>
              </Col>
            </div>
          ))}
        </Row>

        <Row justify="center" style={{ marginTop: 42 }}>
          <HaveCoupon />
          <Payment />
        </Row>
        <Card
          className="home__game"
          title={<Typography.Title level={2}>الأنشطة المتاحة</Typography.Title>}
        >
          {/* <Link to="/stages/1" style={{ display: "inline-block" }}>
            <ToStagesButton />
          </Link> */}
          {isPaid ? (
            <Link to="/stages/1" style={{ display: "inline-block" }}>
              <ToStagesButton />
            </Link>
          ) : (
            <Tooltip
              placement="top"
              title="قم بالدفع او ادخل رمز القسيمة ليتم توجيهك للنشاط مباشرة"
              overlayInnerStyle={{ textAlign: "center" }}
            >
              <i></i> <ToStagesButton disabled={true} />
            </Tooltip>
          )}
        </Card>
      </Content>
    </MainLayout>
  );
};

export default Home;
