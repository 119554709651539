import { useContext } from "react";
import { UserEmailContext, UsernameContext } from "../layout/App/App";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";

const TestPrivateRoutes = () => {
  const userEmail = useContext(UserEmailContext);
  const userName = useContext(UsernameContext);

  const [paymentCookies] = useCookies(["p_completed"]);

  const decryptedToken =
    paymentCookies.p_completed &&
    CryptoJS.AES.decrypt(
      paymentCookies.p_completed,
      process.env.REACT_APP_randomly_secure_payment_key
    ).toString(CryptoJS.enc.Utf8);
  const isPaid =
    decryptedToken === `V1_Payment_For_${userEmail}_${userName}_V1`;

  return isPaid ? <Outlet /> : <Navigate to="/" />;
};

export default TestPrivateRoutes;
