import { Layout, Card, Typography } from "antd";
import MainLayout from "../../layout/MainLayout";
const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Terms = () => {
  return (
    <MainLayout theme="light">
      <Content className="terms__content">
        <Title style={{ color: "#49465f" }} level={2}>
          أحكام و شروط الإستخدام
        </Title>
        <Card style={{ width: "93vw" }}>
          <Title level={3} style={{ marginBottom: "30px" }}>
            تحكم هذه الشروط وسياسة الخصوصية استخدامك أو زيارتك لما يلي:
          </Title>
          <Paragraph>
            التطبيق الإلكتروني تحت اسم نسبة الإبداع يشار إليها إجمالاً فيما بعد
            باسم "الخدمة "، " خدمتنا"، " خدماتنا" أو "الخدمات" حسب ما تقتضيه
            الحالة. المالك أو الملاك يُشار إليهم فيما بعد باسم "نحن" أو "لنا"،
            حسب ما تقتضيه الحالة.
          </Paragraph>
          <Paragraph>
            نحترم حقوق الخصوصية لزوارنا (يشار إليها إجمالاً فيما بعد باسم
            "المستخدمون"، "المستخدمين"، "المستخدم" أو "أنت" حسب ما تقتضيه
            الحالة) ونقر بأهمية حماية المعلومات التي يتم جمعها عنك.
          </Paragraph>
          <Paragraph>
            لقد اعتمدنا سياسة الخصوصية التي توجه كيفية جمع وتخزين واستخدام
            المعلومات التي نجمعها والتي تقدمها لنا.
          </Paragraph>
          <Paragraph>
            تحكم سياسة الخصوصية هذه عند زيارتك للخدمات أو استخدامها، لذا يرجى
            قراءة هذه السياسة بعناية.
          </Paragraph>
          <Paragraph>
            من خلال زيارة الخدمة أو استخدامها، فإنك تقبل وتوافق على الممارسات
            الموضحة في سياسة الخصوصية ما لم يتم تحديد خلاف ذلك في سياسة الخصوصية
            هذه، فإن المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني
            الواردة في الشروط والأحكام الخاصة بنا.
          </Paragraph>
        </Card>
      </Content>
    </MainLayout>
  );
};

export default Terms;
