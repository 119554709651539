import { useState } from "react";
import { Collapse, Form, Row, Col, Button, DatePicker } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const Filter = ({ setIsLoading, tests, setFilteredTests }) => {
  const [isPickerOpen, setIsPickerOpen] = useState();
  const [rangePickerValue, setRangePickerValue] = useState([]);

  const handlePickerOpen = (open) => setIsPickerOpen(open);

  const handleRangePickerChange = (dates) => setRangePickerValue(dates);

  return (
    <Collapse
      size="large"
      bordered={false}
      expandIconPosition="end"
      style={{
        backgroundColor: "white",
        marginBottom: "20px",
        width: "100%",
        borderRadius: "15px",
      }}
    >
      <Collapse.Panel
        header={
          <>
            <FilterOutlined style={{ margin: "0 5px" }} />
            مرشحات البيانات
          </>
        }
        key="1"
      >
        <Form
          layout="vertical"
          onFinish={() => {
            setIsLoading(true);
            const startDate = dayjs(rangePickerValue[0]).startOf("day");
            const endDate = dayjs(rangePickerValue[1]).endOf("day");
            let filtered_tests = [];

            tests.forEach((test) => {
              const test_date = dayjs(test.date_added.substring(0, 10)).hour(9);
              if (test_date.isBetween(startDate, endDate)) {
                filtered_tests.push(test);
              }
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 700);
            setFilteredTests(filtered_tests);
          }}
        >
          <Row gutter={[0, 32]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                onOpenChange={handlePickerOpen}
                open={isPickerOpen}
                allowClear={false}
                onChange={handleRangePickerChange}
                value={rangePickerValue}
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
                size="large"
              />
            </Col>

            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Button htmlType="submit" type="primary">
                  تطبيق
                </Button>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setIsLoading(true);
                    setFilteredTests(tests);
                    setRangePickerValue([0, 0]);
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 700);
                  }}
                >
                  مسح
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default Filter;
