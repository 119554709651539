import { FloatButton, message, Popconfirm } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { handleLogOut } from "../../utils/handleLogOut";
import { useCookies } from "react-cookie";

const LogoutButton = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [, , removePaymentCookie] = useCookies(["p_completed"]);

  return (
    <>
      {contextHolder}
      <Popconfirm
        title="تسجيل الخروج"
        description="هل أنت متأكد من أنك تريد تسجيل الخروج؟"
        onConfirm={() =>
          handleLogOut(messageApi, navigate, removePaymentCookie)
        }
        okText="نعم"
        cancelText="لا"
      >
        <FloatButton
          icon={<LogoutOutlined style={{ color: "#ff4d4f" }} />}
          tooltip="تسجيل الخروج"
        />
      </Popconfirm>
    </>
  );
};

export default LogoutButton;
