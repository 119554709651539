import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { deleteQuestionsSessions } from "../../utils/deleteQuestionsSessions";
const ToStagesButton = ({ disabled = false }) => {
  return (
    <Button
      disabled={disabled}
      className="flex align-center"
      style={{
        height: "fit-content",
        direction: "ltr",
      }}
      icon={<LeftOutlined style={{ fontSize: 24 }} />}
      onClick={() => {
        window.scrollTo(0, 0);
        deleteQuestionsSessions();
      }}
    >
      <Row>
        <Col span={24}>
          <Typography.Title
            className="responsive-h3"
            level={3}
            style={{ width: "100%" }}
          >
            أنشطة نسبة الإبداع
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Title
            className="responsive-h3"
            level={3}
            style={{ width: "100%" }}
          >
            مرحلتين - 6 أسئلة
          </Typography.Title>
        </Col>
      </Row>
    </Button>
  );
};

export default ToStagesButton;
