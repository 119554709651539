import { useEffect, useState } from "react";
import { Layout, Button, Tooltip, Typography, Table, message } from "antd";
import {
  DownloadOutlined,
  LoadingOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import MainLayout from "../../layout/MainLayout";
import { handleDownload, handlePreview } from "../../utils/handleTest";
import { getToken } from "../../utils/getToken";
import Filter from "../../components/Filter/Filter";
const { Content } = Layout;

const AdminTests = () => {
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfPreviewed, setIsPdfPreviewed] = useState(false);
  const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_all_tests_api, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        setTests(response.data.data.user_tests_info);
        setFilteredTests(response.data.data.user_tests_info);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const columns = [
    {
      title: "الإختبار",
      dataIndex: "test_id",
      key: "test_id",
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: "التاريخ",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "الإسم",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "معرف الطالب",
      dataIndex: "student_id",
      key: "student_id",
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: "البريد الإلكتروني",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "تاريخ الميلاد",
      dataIndex: "birth_date",
      key: "birth_date",
    },
    {
      title: "التحكم",
      key: "action",
      fixed: "right",
      width: "160px",
      render: (_, record) => (
        <div>
          <Tooltip
            title={
              isPdfPreviewed ? (
                <Typography.Text style={{ color: "white" }}>
                  يرجى الإنتظار <LoadingOutlined />
                </Typography.Text>
              ) : (
                "عرض التقرير"
              )
            }
          >
            <Button
              type="primary"
              shape="round"
              icon={<EyeOutlined />}
              size="large"
              style={{ marginLeft: "10px" }}
              onClick={() =>
                handlePreview(
                  record.test_id,
                  record.student_id,
                  setIsPdfPreviewed,
                  messageApi
                )
              }
            />
          </Tooltip>
          <Tooltip
            title={
              isPdfDownloaded ? (
                <Typography.Text style={{ color: "white" }}>
                  يرجى الإنتظار <LoadingOutlined />
                </Typography.Text>
              ) : (
                "تحميل التقرير"
              )
            }
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              onClick={() =>
                handleDownload(
                  record.test_id,
                  record.student_id,
                  setIsPdfDownloaded,
                  messageApi
                )
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const data = filteredTests.map((test, index) => ({
    key: index,
    test_id: test.id,
    date: test.date_added,
    name: `${test?.user?.firstname} ${test?.user?.lastname ?? ""}`,
    student_id: test?.user?.id,
    email: test?.user?.email,
    birth_date: test?.user?.birth_date,
  }));

  return (
    <MainLayout theme="dark">
      {contextHolder}
      <Content className="tests__content__wrapper">
        <Typography.Title level={2} style={{ color: "white" }}>
          التقارير
        </Typography.Title>
        <Typography.Title
          level={3}
          style={{ color: "white", textAlign: "center", marginBottom: "30px" }}
        >
          يمكنك إستعراض و تحميل نتائج جميع التقارير
        </Typography.Title>
        <Filter
          setIsLoading={setIsLoading}
          tests={tests}
          setFilteredTests={setFilteredTests}
        />
        <Table
          rowKey="test_id"
          className="tests__table"
          loading={isLoading}
          size="large"
          scroll={{ scrollToFirstRowOnChange: true, x: 1550, y: 500 }}
          style={{ overflow: "hidden", borderRadius: "15px" }}
          pagination={{
            showTotal: (total) => (
              <Typography.Text
                style={{ color: "white" }}
              >{`المجموع ${total} إختبار`}</Typography.Text>
            ),
            position: ["bottomCenter"],
            pageSizeOptions: [10, 20, 30, 40, 50],
            showSizeChanger: true,
            responsive: true,
          }}
          columns={columns}
          dataSource={data}
        />
      </Content>
    </MainLayout>
  );
};

export default AdminTests;
