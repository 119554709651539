const KuwaitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 12 1"
    >
      <rect width="12" height="6" fill="#007a3d" />
      <rect width="12" height="4" y="2" fill="#fff" />
      <rect width="12" height="2" y="4" fill="#ce1126" />
      <polygon points="0,0 3,2 3,4 0,6" />
    </svg>
  );
};

export default KuwaitIcon;
