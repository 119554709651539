import { Button, Form, Input, Modal, message } from "antd";
import React, { useContext, useState } from "react";
import { UserEmailContext, UsernameContext } from "../../layout/App/App";
import axios from "axios";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";

const HaveCoupon = () => {
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userEmail = useContext(UserEmailContext);
  const userName = useContext(UsernameContext);

  const [messageApi, contextHolder] = message.useMessage();

  const [paymentCookies, setPaymentCookie] = useCookies(["p_completed"]);

  const decryptedToken =
    paymentCookies.p_completed &&
    CryptoJS.AES.decrypt(
      paymentCookies.p_completed,
      process.env.REACT_APP_randomly_secure_payment_key
    ).toString(CryptoJS.enc.Utf8);
  const isPaid =
    decryptedToken === `V1_Payment_For_${userEmail}_${userName}_V1`;

  return (
    <>
      {contextHolder}
      <Button
        size="large"
        shape="round"
        style={{ margin: 12, fontSize: 24, height: "fit-content" }}
        onClick={() => setOpen(true)}
        className={isPaid ? "disabled-payment-btn" : ""}
        disabled={isPaid}
      >
        لديك قسيمة؟
      </Button>
      <Modal
        open={open}
        title="من فضلك ادخل القسيمة"
        okText="تحقق من القسيمة"
        cancelText="الغاء"
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        confirmLoading={isLoading}
        onOk={() => {
          setIsLoading(true);
          form
            .validateFields()
            .then((values) => {
              const formData = new FormData();
              formData.append("coupon_code", values.coupon_code);
              formData.append("user_name", userName);
              formData.append("user_email", userEmail);

              axios
                .post(process.env.REACT_APP_check_copoun, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((val) => {
                  setIsLoading(false);
                  if (val.status === 200 || val.status === 201) {
                    form.resetFields();
                    setOpen(false);

                    const encryptedToken =
                      userEmail &&
                      userName &&
                      CryptoJS.AES.encrypt(
                        `V1_Payment_For_${userEmail}_${userName}_V1`,
                        process.env.REACT_APP_randomly_secure_payment_key
                      ).toString();
                    setPaymentCookie("p_completed", encryptedToken, {
                      path: "/",
                    });

                    messageApi.success("تم ادخال رمز القسيمة بنجاح");
                  } else messageApi.warning(val.data.message);
                })
                .catch((err) => {
                  setIsLoading(false);
                  form.resetFields();
                  setOpen(false);
                  if (err?.response?.status === 400) {
                    messageApi.error(
                      err?.response?.data?.message ||
                        "حدث خطأ أثناء إستخدام القسيمه"
                    );
                  }
                  console.log(err);
                });
            })
            .catch((info) => {
              setIsLoading(false);
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="coupon_code"
            label="الكود"
            rules={[
              {
                required: true,
                message: "من فضلك ادخل كود القسيمة!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default HaveCoupon;
