import { Layout, Col, Row, Button, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import MainLayout from "../../layout/MainLayout";
const { Content } = Layout;
const { Paragraph, Title, Text } = Typography;

const Details = ({
  part,
  title,
  no_questions,
  type,
  description,
  question_id,
}) => {
  return (
    <MainLayout theme="dark">
      <Content className="details__content__wrapper">
        <Row justify="center" align="middle" gutter={[12, 0]}>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 16 }}
            style={{ textAlign: "center" }}
          >
            <img
              className="details__content__image"
              src="/images/details.svg"
              alt="details"
            />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Card
              title={
                <div>
                  <Title
                    level={4}
                    style={{
                      color: "#858494",
                      marginTop: "10px",
                      whiteSpace: "normal",
                    }}
                  >
                    {part}
                  </Title>
                  <Title
                    level={3}
                    style={{ marginTop: 0, whiteSpace: "normal" }}
                  >
                    {title}
                  </Title>
                </div>
              }
            >
              <Row>
                <Col
                  className="details__info info__rightBorder"
                  span={12}
                  md={{ span: 10, offset: 2 }}
                >
                  <img src="/images/question.svg" alt="question" />
                  <p>{no_questions}</p>
                </Col>
                <Col
                  className="details__info info__leftBorder"
                  span={12}
                  md={{ span: 10 }}
                >
                  <img src="/images/puzzle.svg" alt="puzzle" />
                  <p>{type}</p>
                </Col>
              </Row>
              <Typography>
                <Title
                  level={4}
                  style={{
                    color: "#858494",
                    marginTop: "10px",
                  }}
                >
                  وصف الأنشطة
                </Title>
                <Paragraph style={{ textAlign: "justify" }}>
                  {description}
                </Paragraph>
                <Title
                  level={4}
                  style={{
                    color: "#858494",
                    marginTop: "10px",
                  }}
                >
                  التعليمات
                </Title>
                <Paragraph style={{ textAlign: "justify" }}>
                  <Text strong>
                    للحصول على تقرير الإختبار يجب مراعاة التالي:
                  </Text>
                  <ol>
                    <li>أن تتم الإجابة على جميع الأنشطة.</li>
                    <li>أن تكون الإجابات ذات صلة بالسؤال.</li>
                    <li>أن يكون هناك سطر (enter) بين كل إجابة.</li>
                  </ol>
                </Paragraph>
              </Typography>
              <div className="details__next">
                <Link to={`questions/${question_id}`}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      question_id === 1 && sessionStorage.setItem("timer", 180);
                    }}
                  >
                    ابدأ النشاط
                  </Button>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Content>
    </MainLayout>
  );
};

export default Details;
