import { useEffect } from "react";
import { Layout, Button, Typography, Card, message } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getToken } from "../../utils/getToken";
import { handleDownload, handlePreview } from "../../utils/handleTest";
import { deleteQuestionsSessions } from "../../utils/deleteQuestionsSessions";
import { useCookies } from "react-cookie";
const { Content } = Layout;

const Confirmation = () => {
  const [isPreparing, setIsPreparing] = useState(false);
  const [isPdfPreviewed, setIsPdfPreviewed] = useState(false);
  const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState("");
  const [testId, setTestId] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const { pathname } = useLocation();
  const [, , removePaymentCookie] = useCookies(["p_completed"]);
  const answers = [
    { id: 1, ans: sessionStorage.getItem("q1_answer") },
    { id: 2, ans: sessionStorage.getItem("q2_answer") },
    { id: 3, ans: sessionStorage.getItem("q3_answer") },
    { id: 4, ans: sessionStorage.getItem("q4_answer") },
    { id: 5, ans: sessionStorage.getItem("q5_answer") },
    { id: 6, ans: sessionStorage.getItem("q6_answer") },
  ];
  const timer = [
    { id: 1, time: 180 - Number(sessionStorage.getItem("q1_timer")) },
    { id: 2, time: 180 - Number(sessionStorage.getItem("q2_timer")) },
    { id: 3, time: 180 - Number(sessionStorage.getItem("q3_timer")) },
    { id: 4, time: 180 - Number(sessionStorage.getItem("q4_timer")) },
    { id: 5, time: 180 - Number(sessionStorage.getItem("q5_timer")) },
    { id: 6, time: 180 - Number(sessionStorage.getItem("q6_timer")) },
  ];

  useEffect(() => {
    if (pathname === "/confirmation") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, [pathname]);

  const calculateTotalTimer = () => {
    let mins = 0;
    let seconds = 0;
    let total = 0;
    timer.forEach((count) => {
      total = total + count.time;
    });
    mins = Math.floor(total / 60);
    seconds = total % 60;
    return `${mins}:${seconds}`;
  };

  const submitTest = () => {
    setIsPreparing(true);
    axios
      .post(
        process.env.REACT_APP_questions_api,
        {
          question_1: answers[0].ans,
          question_2: answers[1].ans,
          question_3: answers[2].ans,
          question_4: answers[3].ans,
          question_5: answers[4].ans,
          question_6: answers[5].ans,
          test_time: calculateTotalTimer(),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setUserId(response.data.data.user_id);
          setTestId(response.data.data.report_id);
        } else {
          setError(true);
        }
        setIsPreparing(false);
        removePaymentCookie("p_completed", {
          path: "/",
        });
      })
      .catch((err) => {
        setError(true);
        removePaymentCookie("p_completed", {
          path: "/",
        });
        console.log(err.message);
      });
  };

  return (
    <Layout className="confirmation__layout">
      {contextHolder}
      <Content className="confirmation__content__wrapper">
        <Typography.Title level={2} style={{ color: "white" }}>
          شكراً لك!
        </Typography.Title>
        <Typography.Title
          level={4}
          style={{ color: "white", margin: "15px 0" }}
        >
          اضغط إعداد التقرير لتحليل إجاباتك و إعداد تقريرك المفصل
        </Typography.Title>
        {testId ? (
          <div className="confirmation__success__wrapper">
            <Button
              size="large"
              icon={<EyeOutlined />}
              onClick={() =>
                handlePreview(testId, userId, setIsPdfPreviewed, messageApi)
              }
              loading={isPdfPreviewed}
            >
              عرض التقرير
            </Button>
            <Button
              size="large"
              icon={<DownloadOutlined />}
              onClick={() =>
                handleDownload(testId, userId, setIsPdfDownloaded, messageApi)
              }
              loading={isPdfDownloaded}
            >
              تحميل التقرير
            </Button>
            <Link to="/tests">
              <Button
                size="large"
                onClick={() => {
                  window.scrollTo(0, 0);
                  deleteQuestionsSessions();
                }}
              >
                عرض جميع التقارير
              </Button>
            </Link>
          </div>
        ) : error ? (
          <div className="confirmation__error__wrapper">
            <Card
              title="حدث خطأ أثناء إنشاء التقرير"
              style={{ textAlign: "right" }}
            >
              <Typography.Paragraph strong>
                تعذر إصدار التقرير لوجود إجابات غير منطقية/عشوائية.
              </Typography.Paragraph>
            </Card>
            <Link to="/">
              <Button
                size="large"
                onClick={() => {
                  window.scrollTo(0, 0);
                  deleteQuestionsSessions();
                }}
              >
                انتقل إلى الصفحة الرئيسية
              </Button>
            </Link>
          </div>
        ) : (
          <Button
            size="large"
            style={{ width: "150px" }}
            onClick={submitTest}
            loading={isPreparing}
          >
            إعداد التقرير
          </Button>
        )}
      </Content>
    </Layout>
  );
};

export default Confirmation;
