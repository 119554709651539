import { message, Row, Col } from "antd";
import config from "../../configs";
import LoginCard from "../../components/LoginCard";

const Login = ({ setToken }) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div className="auth__wrapper">
      {contextHolder}
      <Row
        gutter={[0, 24]}
        justify="space-around"
        align="middle"
        className="auth__content"
      >
        <Col xs={{ span: 24 }} xl={{ span: 6 }}>
          <img src={config.APP_LOGO_PATH} alt="LOGO" className="auth__logo" />
        </Col>

        <Col
          xs={{ span: 24 }}
          xl={{ span: 11 }}
          style={{ textAlign: "center" }}
        >
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/792754794?h=32080ccaae"
            className="login__video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </Col>

        <Col
          xs={{ span: 22 }}
          sm={{ span: 12 }}
          xl={{ span: 6 }}
          className="login__card__col"
        >
          <LoginCard
            messageApi={messageApi}
            setToken={setToken}
            isAdmin={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
