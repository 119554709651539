import { FloatButton, Popconfirm } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const BackToHomeButton = () => {
  const navigate = useNavigate();
  const [, , removePaymentCookie] = useCookies(["p_completed"]);

  return (
    <Popconfirm
      title="العودة إلى الصفحة الرئيسية"
      description="هل أنت متأكد من أنك تريد العودة إلى الصفحة الرئيسية؟"
      onConfirm={() => {
        removePaymentCookie("p_completed", {
          path: "/",
        });
        navigate("/");
      }}
      okText="نعم"
      cancelText="لا"
    >
      <FloatButton
        icon={<HomeOutlined style={{ color: "#6A5AE0" }} />}
        tooltip="الصفحة الرئيسية"
      />
    </Popconfirm>
  );
};

export default BackToHomeButton;
