import axios from "axios";
import { getToken } from "./getToken";

export const handleLogOut = (messageApi, navigate, removePaymentCookie) => {
  messageApi.open({
    key: "logout",
    type: "loading",
    content: "جاري تسجيل الخروج",
    duration: 0,
  });
  axios
    .get(process.env.REACT_APP_logout_api, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      messageApi.destroy("logout");
      if (response.data.success) {
        sessionStorage.removeItem("isAdmin");
        sessionStorage.removeItem("session_token");
        document.cookie =
          "session_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        removePaymentCookie("p_completed", {
          path: "/",
        });
        navigate("/login");
      } else {
        messageApi.info("!تعذر تسجيل خروجك. حاول مرة أخرى");
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};
