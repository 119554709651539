import { useEffect, useState } from "react";
import {
  Layout,
  Card,
  Typography,
  Row,
  Col,
  Image,
  Divider,
  Skeleton,
  Button,
  message,
  Popconfirm,
  Tag,
} from "antd";
import { LogoutOutlined, RollbackOutlined } from "@ant-design/icons";
import axios from "axios";
import MainLayout from "../../layout/MainLayout";
import { getToken } from "../../utils/getToken";
import { useNavigate } from "react-router-dom";
import { handleLogOut } from "../../utils/handleLogOut";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
const { Content } = Layout;
const { Paragraph, Text, Title } = Typography;

const Profile = () => {
  const isAdmin = sessionStorage.getItem("isAdmin") === "true" ? true : false;
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [userData, setUserData] = useState({});
  const [countriesData, setCountriesData] = useState({});
  const [, , removePaymentCookie] = useCookies(["p_completed"]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_user_api, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => setUserData(response.data.data))
      .catch((err) => {
        console.log(err.message);
      });
    axios
      .get(process.env.REACT_APP_countries_api, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => setCountriesData(response.data.data))
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <MainLayout theme="light">
      {contextHolder}
      <Content style={{ margin: "4vh auto" }}>
        <Typography>
          <Card style={{ width: "85vw" }}>
            {userData.id ? (
              <Row gutter={[16, 16]}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 7 }}
                  lg={{ span: 5 }}
                  style={{ textAlign: "center" }}
                >
                  <Image
                    width={150}
                    className="profile__avatar"
                    src={
                      userData.user_gender === "ذكر" ||
                      userData.user_gender === "male"
                        ? "images/male.png"
                        : "images/female.png"
                    }
                    preview={false}
                  />
                  <Paragraph style={{ margin: "20px 0" }}>{`${
                    userData.user_firstname
                  } ${userData.user_lastname ?? ""}`}</Paragraph>
                  <Paragraph>{userData.user_email}</Paragraph>
                  {isAdmin && (
                    <Tag
                      color="rgb(139, 0, 0)"
                      style={{ display: "inline-block" }}
                    >
                      مسؤول
                    </Tag>
                  )}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 17 }}
                  lg={{ span: 19 }}
                  className="profile__details"
                >
                  <Row gutter={[16, 32]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Title level={4}>معرف المستخدم</Title>
                      <Text>{userData.id ?? "-"}</Text>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Title level={4}>تاريخ الإنضمام</Title>
                      <Text>
                        {userData.date_added
                          ? userData.date_added.substring(0, 10)
                          : "-"}
                      </Text>
                    </Col>
                    <Divider className="profile__divider" />
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Title level={4}>تاريخ الميلاد</Title>
                      <Text>{userData.user_birth_date ?? "-"}</Text>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Title level={4}>النوع</Title>
                      <Text>{userData.user_gender ?? "-"}</Text>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Title level={4}>الصف الدراسي</Title>
                      <Text>{userData.user_school_grade ?? "-"}</Text>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Title level={4}>الدولة</Title>
                      <Text>
                        {userData.user_countrycode
                          ? countriesData[userData.user_countrycode]
                          : "-"}
                      </Text>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Title level={4}>المدينة</Title>
                      <Text>{userData.user_city ?? "-"}</Text>
                    </Col>
                    <Col xs={{ span: 24 }}>
                      <Popconfirm
                        title="تسجيل الخروج"
                        description="هل أنت متأكد من أنك تريد تسجيل الخروج؟"
                        onConfirm={() =>
                          handleLogOut(
                            messageApi,
                            navigate,
                            removePaymentCookie
                          )
                        }
                        okText="نعم"
                        cancelText="لا"
                      >
                        <Button
                          size="large"
                          type="primary"
                          danger
                          icon={<LogoutOutlined />}
                          style={{ float: "left" }}
                        >
                          تسجيل الخروج
                        </Button>
                      </Popconfirm>
                      {isAdmin && (
                        <Link to="/admin/tests">
                          <Button
                            size="large"
                            type="primary"
                            icon={<RollbackOutlined />}
                            style={{ float: "left" }}
                            className="profile__get__back"
                          >
                            العودة إلى التقارير
                          </Button>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Skeleton active avatar paragraph={{ rows: 7 }} />
            )}
          </Card>
        </Typography>
      </Content>
    </MainLayout>
  );
};

export default Profile;
