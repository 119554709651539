import { Typography, Button } from "antd";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

export default function ErrorPage() {
  const isAdmin = sessionStorage.getItem("isAdmin") === "true" ? true : false;

  return (
    <div id="error-page">
      <div className="error-content">
        <Title level={2} style={{ color: "white" }}>
          404
        </Title>
        <Title level={3} style={{ color: "white" }}>
          لم يتم العثور على الصفحة
        </Title>
        <Paragraph style={{ color: "white" }}>
          عفواً! لم يتم العثور على الصفحة! بإمكانك الإنتقال إلى
          {isAdmin ? " التقارير " : " الصفحة الرئيسية "}
          من الرابط أدناه.
        </Paragraph>
        <Link to={isAdmin ? "/admin/tests" : "/"}>
          <Button
            size="large"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            {isAdmin ? "انتقل إلى التقارير" : "انتقل إلى الصفحة الرئيسية"}
          </Button>
        </Link>
      </div>
    </div>
  );
}
